<template>
    <v-card tile class="pa-3 ma-10">
        <v-card-title>
            システム更新履歴
        </v-card-title>
        <v-simple-table>
            <tbody>
                <tr>
                    <th>バージョン</th>
                    <th>日時</th>
                    <th>更新内容</th>
                </tr>
                <tr v-for="log in logs" :key="log.version">
                    <td>{{ log.version }}</td>
                    <td>{{ log.date }}</td>
                    <td v-html="log.message"></td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>

<script>
import { logs } from '@/libs/version'

export default {
    data: () => ({
        logs
    })
}
</script>
